import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceDispatcherService {

  constructor(private http: HttpClient) { }

  /**
  * Method: get(urlEndPoint)
  * Purpose: sending httpClient req and getting response from server
  * created on: 19-sep-2019
  */
  get(urlEndPoint, data) {

    return this.http.get(urlEndPoint, { params: data }).pipe(
      tap(res => {
        return res;
      }, err => {
        return err;
      })
    );
  }

  /**
  * Method: post(urlEndPoint,data)
  * Purpose: sending httpClient req and getting response from server
  * created on: 19-sep-2019
  */
  post(urlEndPoint, data) {
    return this.http.post(urlEndPoint, data).pipe(
      tap(res => {
        return res;
      }, err => {
        return err;
      })
    );
  }

  /**
  * Method: delete(urlEndPoint,data)
  * Purpose: sending httpClient delete req and getting response from server
  * created on: 19-sep-2019
  */
  delete(urlEndPoint, data) {
    return this.http.delete(urlEndPoint, { params: data }).pipe(
      tap(res => {
        return res;
      }, err => {
        return err;
      })
    );
  }

  /**
  * Method: put(urlEndPoint,data)
  * Purpose: sending httpClient delete req and getting response from server
  * created on: 19-sep-2019
  */
  put(urlEndPoint, data) {
    return this.http.put(urlEndPoint, data).pipe(
      tap(res => {
        return res;
      }, err => {
        return err;
      })
    );
  }

  getIpaddress(data = {}) {
    return this.http.get("https://api.ipify.org/?format=json"); 
  }

}

