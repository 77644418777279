
<div class="animation-zoom-in loader" *ngIf="show">
    <!--- Loader Div Start Here -->
    <div class="loader-wrapper">
        <div class="site-loader">
            <div class="loader-inner ball-spin-fade-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    <!--- Loader Div End Here -->
</div>