import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import { environment } from '../../environments/environment';
import { EncryptionService } from '../services/encryption-service/encryption.service';
import { CompileShallowModuleMetadata } from '@angular/compiler';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    clientSecret = environment.ClientSecret;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private router: Router,
        private encryptionService: EncryptionService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        // sending incoming request as it is to the login or forgotPassword api without setting headers
        // tslint:disable-next-line: max-line-length
        if (request.url.indexOf('login') > 0
            || request.url.indexOf('forgot-password') > 0
            || request.url.indexOf('resetPassword') > 0
            || request.url.indexOf('verifyLink') > 0
            || request.url.indexOf('access-token') > 0
            || request.url.indexOf('set-password') > 0
            || request.url.indexOf('user-email-verify') > 0
            || request.url.indexOf('verify-register-email') > 0
            || request.url.indexOf('verify-password-link') > 0
            || request.url == 'https://api.ipify.org/?format=json') {

           
                if (request.url == 'https://api.ipify.org/?format=json') {
                    const setHeader = request.clone({ setHeaders: { "Content-Type": "application/json" } });
                    return next.handle(setHeader);
                  } else {
                    const setHeader = request.clone({ setHeaders: { client_secret: this.clientSecret } });
                    return next.handle(setHeader);
                  }
        }
        else {     
            
           // console.log("intecrcpetinh")

           // console.log(request,this.encryptionService.decryptUsingAES256(localStorage.getItem('accessToken')))

            let accessTOken = this.encryptionService.decryptUsingAES256(localStorage.getItem('accessToken'));

            if(!accessTOken){
                accessTOken = ''
            }
            // sending incoming request by appending token to header
            return next.handle(request.clone({ setHeaders: { access_token: accessTOken, lang: localStorage.getItem('lang') ? localStorage.getItem('lang'): 'en' , ip: localStorage.getItem('ip') ? localStorage.getItem('ip') : "192.169.1.50" } }))
                .catch(error => {
                    // to handle 501 unauthorized token and generating refresh token appending it to header
                    // console.log(error.status);
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        return this.authService.createNewToken().switchMap((res: any) => {
                            const newreq = request.clone({ setHeaders: { access_token: res.accessToken } });
                            return next.handle(newreq);
                        });
                    } else {
                        // localStorage.clear();
                        // this.router.navigateByUrl('');
                        return throwError(error);
                    }
                });
        }
    }
}
