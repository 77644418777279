const development = {
  production: false,
  AesSecretKey: '08277A08B0ABA70703E28A5FCED7396D',
  AesSecretIVKey: 'D9062EA86462F77E',
  baseUrl: 'https://3j71v1wtsk.execute-api.ap-southeast-2.amazonaws.com/dev/api/v1/',
  baseJobUrl:'https://u2gu5c7ewb.execute-api.ap-southeast-2.amazonaws.com/dev/api/v1/',
  baseAppUrl: 'https://3j71v1wtsk.execute-api.ap-southeast-2.amazonaws.com/dev/app/api/v1/user/',
  basePaymentUrl:'https://bt67zm1j18.execute-api.ap-southeast-2.amazonaws.com/dev/api/v1/',
  ClientSecret: '5c3d46a7c0d6dc57a9d817a1dec383b027c5c0ef476084ec9264842790ee271b',
  ratingCondition:1.7
}

const staging = {
  production: false,
  AesSecretKey: '08277A08B0ABA70703E28A5FCED7396D',
  AesSecretIVKey: 'D9062EA86462F77E',
  baseUrl: 'https://pd7a3l4874.execute-api.ap-southeast-2.amazonaws.com/staging/api/v1/',
  baseJobUrl:'https://8gk7q7yoo3.execute-api.ap-southeast-2.amazonaws.com/staging/api/v1/',
  baseAppUrl: 'https://pd7a3l4874.execute-api.ap-southeast-2.amazonaws.com/staging/app/api/v1/user/',
  basePaymentUrl:'https://i02jo3prsg.execute-api.ap-southeast-2.amazonaws.com/staging/api/v1/',
  ClientSecret: '5c3d46a7c0d6dc57a9d817a1dec383b027c5c0ef476084ec9264842790ee271b',
  ratingCondition:1.7
}

const production = {
  production: false,
  AesSecretKey: '08277A08B0ABA70703E28A5FCED7396D',
  AesSecretIVKey: 'D9062EA86462F77E',
  baseUrl: 'https://1htasv4bmd.execute-api.ap-southeast-2.amazonaws.com/prod/api/v1/',
  baseJobUrl:'https://jnx5dtbfpl.execute-api.ap-southeast-2.amazonaws.com/prod/api/v1/',
  baseAppUrl: 'https://3j71v1wtsk.execute-api.ap-southeast-2.amazonaws.com/dev/app/api/v1/user/',
  basePaymentUrl:'https://i02jo3prsg.execute-api.ap-southeast-2.amazonaws.com/staging/api/v1/',
  ClientSecret: '5c3d46a7c0d6dc57a9d817a1dec383b027c5c0ef476084ec9264842790ee271b',
  ratingCondition:1.7
}

export {development,staging,production}