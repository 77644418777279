import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ServiceDispatcherService } from '../../service-dispatcher/service-dispatcher.service';
import { environment } from 'src/environments/environment';
import { EncryptionService } from '../../services/encryption-service/encryption.service';
import { Router } from '@angular/router';
const { detect } = require('detect-browser');


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string;
  baseAppUrl: string;

  constructor(
    private dispatcher: ServiceDispatcherService,
    private encryption: EncryptionService,
    private router: Router
  ) {
    this.baseUrl = environment.baseUrl;
    this.baseAppUrl = environment.baseAppUrl;
  }

  /**
  * Method : login
  * Purpose : Login user
  * @param adminLoginData The parameter for this method 
  */
  login(adminLoginData) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'login', adminLoginData).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method : createNewToken
  * Purpose : Create user token
  */
  createNewToken() {
    const browser = detect();
    let siteAdminId = this.encryption.decryptUsingAES256(localStorage.getItem('siteAdminId'));
    let refreshToken = localStorage.getItem('refreshToken');
    let getToken = {
      "siteAdminId": siteAdminId,
      "deviceId": browser.name,
      "refreshToken": refreshToken
    }
    return this.dispatcher.post(this.baseUrl + 'access-token', getToken)
      .pipe(tap((res: any) => {
        if (res.status) {
          localStorage.setItem('accessToken', res.result.accessToken);
        }
      }, err => {
        localStorage.clear();
        this.router.navigateByUrl('');
       
      }));
  }

  /**
  * Method : verifytoken
  * Purpose : verify token valid or not
  * @param token The parameter for this method 
  */
  verifytoken(token) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUrl + 'verifyLink', { 'verificationToken': token }).subscribe((res: any) => {
        resolve(res);
      }, err => {
     
        reject(err);
      });
    });
  }

  /**
  * Method : refreshToken
  * Purpose : Refresh token if access token is expired
  * @param data The parameter for this method 
  */
  refreshToken(data) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'refreshToken', data).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method : forgetPassword
  * Purpose : Forgot password link send to Email
  * @param data The parameter for this method 
  */
  forgetPassword(data) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'forgot-password', data).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method : resetPassword
  * Purpose : Set new user password 
  * @param data The parameter for this method 
  */
  resetPassword(data) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseUrl + 'reset-password', data).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method : verifyLink
  * Purpose : Verify link valid or not
  * @param guid The parameter for this method 
  */
  verifyLink(guid) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'verify-password-link', guid).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }


  /**
  * Method : verifyUserEmailLink
  * Purpose : user for app user verify link
  * @param params The parameter for this method 
  */
  verifyUserEmailLink(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseAppUrl + 'verify-register-email', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method : verifyUserResetPasswordLink
  * Purpose : verify User Reset Password Link
  * @param params The parameter for this method 
  */
  verifyUserResetPasswordLink(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseAppUrl + 'verify-forget-password-link', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }


  /**
  * Method : resetUserPassword
  * Purpose : Reset User Password
  * @param params The parameter for this method 
  */
  resetUserPassword(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseAppUrl + 'set-password', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getIpAddress() {
    return new Promise((resolve, reject) => {
      this.dispatcher.getIpaddress().subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    })
  }

}
