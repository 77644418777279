export class AppConstant {
  LicenseStatus = [{ Id: 1, value: 'Active' }, { Id: 2, value: 'Inactive' }];
  LicenseCategory = [{ Id: 1, value: 'Air Conditioning' }, { Id: 2, value: 'Arborists' }, { Id: 3, value: 'Architect' }, { Id: 4, value: 'Asbestos Removal' },
  { Id: 5, value: 'Balustrades' }, { Id: 6, value: 'Bathroom Renovations' }];
  clientStatus = [{ id: 0, name: 'All' }, { id: 1, name: 'Active' }, { id: 2, name: 'Inactive' }];
  clientPaymentStatus = [{ id: 0, name: 'All' }, { id: 1, name: 'Urgent' }, { id: 2, name: 'Regular' }];
  roleStatus = [{ id: 0, name: 'All' }, { id: 2, name: 'Client' }, { id: 3, name: 'Tradie' }, { id: 1, name: 'Admin' }, { id: 4, name: 'Site Moderator' }];
  tradieStatus = [{ id: 0, name: 'All' }, { id: 1, name: 'Waiting Approval' }, { id: 2, name: 'Active' }, { id: 3, name: 'Inactive' }, { id: 4, name: 'Rejected' }, { id: 5, name: 'Blocked' }];
  jobStatus = [{ id: 1, name: 'Pending' }, { id: 2, name: 'Accepted' }, { id: 3, name: 'Completed' }];
  tradieType = [{ id: 0, name: 'Un-Licensed' }, { id: 1, name: 'Licensed' }];
  moderatorType = [{ id: 4, name: 'Site Moderator' }];
}
