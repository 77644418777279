import { Component } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin-web';

  constructor(
    public translate: TranslateService,
  ){
    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    translate.addLangs(['en','fr']);
    translate.setDefaultLang(lang ? lang : 'en');
    localStorage.setItem('lang', lang);
    //* default set language 
    translate.use(lang.match(/en|fr/) ? lang : 'en')
  }
  ngOnInit() {
  }


}
